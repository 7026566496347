<template>
  <div>
    <transition name="slide-fade">
      <!-- > -->
      <div id="pagetop" class="fixed bottom-0 z-50 right-1 isolate invert mix-blend-difference" v-if="scy > 10"
        @click="toTop">
        <!--  -->
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 48 48" fill="none" stroke="#000000"
          stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
          <circle style="fill: #FFFFFF;
              stroke: #000000;
              stroke-width: 2;
              stroke-linecap: square;
              stroke-miterlimit: 8;" cx="24" cy="24" r="16" />
          <path style="fill: none;
              stroke: #000000;
              stroke-width: 2;
              stroke-linecap: square;
              stroke-miterlimit: 8;" d="M30.8,27.4L24,20.6l-6.8,6.8" />
        </svg>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>

<script type="ts" setup>
defineProps({
  scy: {
    type: Number,
    default: 0
  }
})

function toTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}
</script>
