<template>
  <ClientOnly>
    <!-- PWA Update Content Prompt -->
    <div v-if="$pwa?.offlineReady || $pwa?.needRefresh" id="toast-interactive"
      class="fixed bottom-0 right-0 w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow z-70 dark:bg-gray-800 dark:text-gray-400"
      role="alert">
      <div class="flex">
        <div
          class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:text-blue-300 dark:bg-blue-900">
          <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97" />
          </svg>
          <span class="sr-only">Refresh icon</span>
        </div>
        <div class="ml-3 text-sm font-normal">
          <span v-if="$pwa.offlineReady" class="mb-1 text-sm font-semibold text-gray-900 dark:text-white">Ready to work
            offline</span>
          <div v-else class="mb-2 text-sm font-normal">New content available, click on reload button to update</div>
          <div v-if="$pwa.needRefresh" class="grid grid-cols-2 gap-2">
            <div>
              <button v-if="$pwa.needRefresh" @click="$pwa.updateServiceWorker()"
                class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-500 dark:hover:bg-primary-600 dark:focus:ring-primary-800">Reload</button>
            </div>
            <div v-if="false">
              <button @click="$pwa.updateServiceWorker()"
                class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700">Cancel</button>
            </div>
          </div>
        </div>
        <button type="button"
          class="ml-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-interactive" aria-label="Close">
          <span class="sr-only">Close</span>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </button>
      </div>
    </div>
    <!-- PWA Install Prompt -->
    <div v-if="$pwa?.showInstallPrompt && $pwa?.offlineReady && !pwa?.needRefresh" id="toast-interactive2"
      class="fixed bottom-0 right-0 w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow z-70 dark:bg-gray-800 dark:text-gray-400"
      role="alert">
      <div class="flex">
        <div
          class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:text-blue-300 dark:bg-blue-900">
          <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97" />
          </svg>
          <span class="sr-only">Refresh icon</span>
        </div>
        <div class="ml-3 text-sm font-normal">
          <span class="mb-1 text-sm font-semibold text-gray-900 dark:text-white">Install PWA
            offline</span>
          <div class="grid grid-cols-2 gap-2">
            <div>
              <button @click="$pwa.install()"
                class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-500 dark:hover:bg-primary-600 dark:focus:ring-primary-800">Reload</button>
            </div>
            <div>
              <button @click="$pwa.cancelInstall()"
                class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700">Cancel</button>
            </div>
          </div>
        </div>
        <button type="button"
          class="ml-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-interactive" aria-label="Close">
          <span class="sr-only">Close</span>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </button>
      </div>
    </div>
  </ClientOnly>
  <div class="bg-primary">
    <div class="container flex flex-col justify-between py-6 sm:flex-row">
      <p class="text-center text-white font-body md:text-left">
        © Copyright 2023. All right reserved, Strong Oak Studios.
      </p>
      <div class="flex items-center justify-center pt-5 sm:justify-start sm:pt-0">
        <a href="https://twitter.com/strongoakstudiosatx" class="pl-4" target="_blank" rel="noopener noreferrer">
          <i class="text-2xl text-white bx bxl-twitter hover:text-highlight"></i>
        </a>
        <a href="https://instagram.com/strongoakstudiosatx" class="pl-4" target="_blank" rel="noopener noreferrer">
          <i class="text-2xl text-white bx bxl-instagram hover:text-highlight"></i>
        </a>
        <a href="#contact" class="pl-4">
          <i class="text-2xl text-white bx bx-envelope hover:text-highlight"></i>
        </a>
      </div>
    </div>
  </div>
</template>
