<template>
  <header class="absolute top-0 z-50 w-full py-3 sm:py-5">
    <div class="container flex items-center justify-between">
      <div>
        <nuxt-link to="/">
          <LayoutNavBarLogo />
        </nuxt-link>
      </div>
      <!-- Large Format Navbar -->
      <div class="hidden lg:block">
        <ul class="flex items-center">
          <li class="pl-6 group">
            <!-- <nuxt-link to="/about" class="cursor-pointer pt-0.5 font-header font-semibold uppercase text-white">
                About
              </nuxt-link> -->
            <a href="#about" class="cursor-pointer pt-0.5 font-header font-semibold uppercase text-white">
              About
            </a>
            <span class="block h-0.5 w-full bg-transparent group-hover:bg-highlight"></span>
          </li>
          <li class="pl-6 group">
            <!-- <nuxt-link to="/contact" class="cursor-pointer pt-0.5 font-header font-semibold uppercase text-white">
                Contact
              </nuxt-link> -->
            <a href="#contact" class="cursor-pointer pt-0.5 font-header font-semibold uppercase text-white">
              Contact
            </a>
            <span class="block h-0.5 w-full bg-transparent group-hover:bg-highlight"></span>
          </li>
        </ul>
      </div>
      <div class="block lg:hidden">
        <button>
          <i class="text-4xl text-white bx bx-menu"></i>
        </button>
      </div>

      <!-- Mobile Format Navbar -->
      <!-- <div
      class="fixed inset-0 min-h-screen transition-opacity bg-black opacity-0 pointer-events-none z-70 bg-opacity-70 lg:hidden"
      :class="{ 'opacity-100 pointer-events-auto': mobileMenu }">
      <div class="absolute right-0 w-2/3 min-h-screen px-8 py-4 shadow bg-primary md:w-1/3">
        <button class="absolute top-0 right-0 mt-4 mr-4" @click="mobileMenu = false">
          <img src="/atom-v2.0.0/assets/img/icon-close.svg" class="w-auto h-10" alt="" />
        </button>

        <ul class="flex flex-col mt-8">

          <li class="py-2">

            <span @click="triggerMobileNavItem('#about')"
              class="cursor-pointer pt-0.5 font-header font-semibold uppercase text-white">About</span>

          </li>
        </ul>
      </div>
    </div> -->
    </div>


  </header>
</template>
