<template>
  <div class="relative">
    <div>
      <!-- <VitePwaManifest /> -->
      <LayoutNavBar />
      <slot />
      <LayoutFooter />
      <LayoutBackToTop :scy="scY" />
    </div>
  </div>
</template>

<script type="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue'
const appConfig = useAppConfig()
const scTimer = ref(0 || null)
const scY = ref(0)


onMounted(() => {
  window.addEventListener('scroll', () => {
    clearTimeout(scTimer.value)
    scTimer.value = setTimeout(() => {
      scY.value = window.scrollY
    }, 10)
  })
})

// useHead({
//   title: appConfig.title,
//   meta: [
//     {
//       name: 'description',
//       content: appConfig.description
//     },
//     { charset: 'utf-8' },
//     { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' },
//     { content: 'IE=edge,chrome=1', 'http-equiv': 'X-UA-Compatible' },
//     { property: 'og:title', content: appConfig.description },
//     { property: 'og:locale', content: 'en_US' },
//     { property: 'og:url', content: '//' },
//     { property: 'og:type', content: 'website' },
//     // Use custom images for “shareable” pages(e.g., homepage, articles, etc.)
//     // Use your logo or any other branded image for the rest of your pages.
//     // Use images with a 1.91: 1 ratio and minimum recommended dimensions of 1200x630 for optimal clarity across all devices.
//     // { property: 'og:image', content: '//' }
//     { name: 'twitter:card', content: 'summary_large_image' },
//     { name: 'twitter:site', content: '@strongoakstudiosatx' },
//   ],
//   link: [
//     {
//       rel: 'icon',
//       type: 'image/png',
//       href: '/icons/favicon.ico'
//     },
//     {
//       rel: 'dns-prefetch',
//       href: 'https://fonts.googleapis.com',
//     },
//     {
//       rel: 'dns-prefetch',
//       href: 'https://fonts.gstatic.com',
//     },
//     {
//       rel: 'dns-prefetch',
//       href: 'https://unpkg.com',
//     },
//     {
//       rel: 'dns-prefetch',
//       href: 'https://strongoakstudios.com',
//     },
//     process.env.NODE_ENV !== 'development' ? {
//       rel: 'dns-prefetch',
//       href: 'http://localhost',
//     } : {},
//     {
//       href: "https://fonts.gstatic.com",
//       rel: "preconnect",
//       crossorigin: 'anonymous'
//     },
//     {
//       as: "style",
//       href: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Raleway:wght@400;500;600;700&display=swap",
//       rel: "preload",
//       crossorigin: 'anonymous'
//     },
//     {
//       href: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Raleway:wght@400;500;600;700&display=swap",
//       rel: "stylesheet",
//       crossorigin: 'anonymous'
//     },

//     {
//       href: "https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css",
//       rel: "stylesheet",
//       crossorigin: 'anonymous'
//     },
//   ],
//   script: [
//     process.env.NODE_ENV !== 'development' ? {
//       defer: true,
//       src: "https://static.cloudflareinsights.com/beacon.min.js",
//       'data-cf-beacon': '{"token": "195980a395bb4537820c2d714623f09b"}'
//     } : {},
//     {
//       defer: true,
//       src: "https://unpkg.com/@alpine-collective/toolkit@1.0.0/dist/cdn.min.js"
//     },
//     {
//       defer: true,
//       src: "https://unpkg.com/alpinejs@3.x.x/dist/cdn.min.js"
//     },
//     // {
//     //   type: "module",
//     //   async: true,
//     //   defer: true,
//     //   src: "https://cdn.jsdelivr.net/npm/friendly-challenge@0.9.12/widget.module.min.js"
//     // },
//     // {
//     //   nomodule: true,
//     //   src: "https://cdn.jsdelivr.net/npm/friendly-challenge@0.9.12/widget.min.js",
//     //   async: true,
//     //   defer: true,
//     // }

//   ],
//   bodyAttrs: {
//     class: 'relative'
//   },
//   htmlAttrs: {
//     lang: 'en'
//   }
// })
</script>
