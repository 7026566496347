declare module 'nuxt/schema' {
  interface AppConfig {
    title: string
    description: string
    theme: {
      dark: boolean
      colors: {
        primary: string
      }
    }
  }
}
export default defineAppConfig({
  title: 'Strong Oak Studios',
  description: 'Strong Oak Studios',
  theme: {
    dark: true,
    colors: {
      primary: '#00af7c'
    }
  }
})
